import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Nav from "../../Components/Navnew/Nav";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { FaLocationArrow } from 'react-icons/fa';
import { IoLocationOutline, IoStar } from "react-icons/io5";
// Import Swiper styles
import SweetAlert from "react-bootstrap-sweetalert";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import { Pagination, Navigation } from "swiper";
import Background from "../../Asset/15.jpg"
var sectionStyle = {
  width: "100%",
  backgroundImage: `url(43873269-da92-4f9c-aa3d-b45fd0084d17.jpg)`,
};

const Home = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate()
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  // popular
  const [bookShow, setBookShow] = useState(false);
  const [popular, setPopular] = useState([]);
  const [isLoading3, setIsLoading3] = useState([]);
  const [data, setData]= useState(null)
  // galery
  const [galery, setGalery] = useState(['https://cdn.tripster.ru/thumbs2/bd866670-b5c1-11ee-b8f5-3221ccd796ca.1600x900.jpeg', 'https://people-travels.com/storage/images/d1ZupoLogtRiRdEocPqkc9Kgad6PdahsvbbXathF.webp', 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Nest_One_Tashkent.jpg', 'https://upload.wikimedia.org/wikipedia/commons/e/e2/Milliy_bog.jpg', 'https://t86d9d.p3cdn1.secureserver.net/wp-content/uploads/2023/12/Ark-of-Bukhara-2048x1536.jpeg', 'https://tripplanet.ru/wp-content/uploads/asia/uzbekistan/tashkent/dostoprimechatelnosti-tashkenta.jpg']);
  const [isLoading2, setIsLoading2] = useState([]);
  useEffect(() => {
    fetch("https://tur.web-sayt.uz/api/tours", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl9pZCI6ImJlODdiMjYwLTFhMTEtNDY0MC1iMzdkLTk4ZmI5YzQ5ZmI2NSIsInVzZXJfaWQiOiI2Mzg4ZWU0NWIxMzk5MTU2OWM0ZTdiMzAiLCJpYXQiOjE2NzIwNjYyNzd9.v22ZaaX_ZFrVIlWf6gaF7w1q1U3x0VJxiGYTKpDpeEI",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTours(data.data.data);
        console.log(data.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  }, []);
  // popular
  useEffect(() => {
    fetch("https://tur.web-sayt.uz/api/tours/popular", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl9pZCI6ImJlODdiMjYwLTFhMTEtNDY0MC1iMzdkLTk4ZmI5YzQ5ZmI2NSIsInVzZXJfaWQiOiI2Mzg4ZWU0NWIxMzk5MTU2OWM0ZTdiMzAiLCJpYXQiOjE2NzIwNjYyNzd9.v22ZaaX_ZFrVIlWf6gaF7w1q1U3x0VJxiGYTKpDpeEI",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPopular(data.data.data);
        console.log(data.data.data);
        setIsLoading3(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading3(true);
      });
  }, []);
  const formBtn = (e) => {
    setTimeout(() => {
      setBookShow(false);
    }, 1000);
    e.preventDefault();
    if (e.target[0].value.length > 0 && e.target[1].value.length > 0) {
      let botMessege = `
Здравствуйте, администратор UZTURAGENCY, новое сообщение! 😊%0A
Название тура  : ${data.title_eng}%0A
Имя    👤: ${e.target[0].value}%0A
Фамилия   👤: ${e.target[1].value}%0A
Телефон номер   📧: ${e.target[1].value}%0A
`;
// Tur narxi : ${Number(data.price * 11700).toLocaleString()} so'm             

      let url = `https://api.telegram.org/bot7859889008:AAHIkSdCOwbYoxmTwIble9J2FXlAKES8vRk/sendMessage?chat_id=-4535883531&text=${botMessege}`;
      async function fetchAsync(url) {
        let response = await fetch(url);
        let data = await response.json();
        return data;
      }
      fetchAsync(url);
      e.target[0].value = "";
      e.target[1].value = "";
      e.target[2].value = "";
    } 
  };
  // useEffect(() => {
  //   fetch("https://tur.web-sayt.uz/api/galleries", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl9pZCI6ImJlODdiMjYwLTFhMTEtNDY0MC1iMzdkLTk4ZmI5YzQ5ZmI2NSIsInVzZXJfaWQiOiI2Mzg4ZWU0NWIxMzk5MTU2OWM0ZTdiMzAiLCJpYXQiOjE2NzIwNjYyNzd9.v22ZaaX_ZFrVIlWf6gaF7w1q1U3x0VJxiGYTKpDpeEI",
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setGalery(data.data.galleries);
  //       console.log(data.data.galleries);
  //       setIsLoading2(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading2(true);
  //     });
  // }, []);
  return (
    <div>
         <SweetAlert
        title={t("info")}
        show={bookShow}
        onCancel={() => setBookShow(false)}
        showConfirm={false}
      >
        <div>
          <form onSubmit={formBtn}>
            <input className="form-control" placeholder={t("name")} required />
            <input
              className="form-control"
              style={{ margin: "10px 0px" }}
              placeholder={t("surname")}
              required
            />
            <input
              className="form-control"
              style={{ margin: "10px 0px" }}
              placeholder={t("number")}
              required
            />
            <button
              className="money glow-on-hover"
              style={{ color: "black", backgrounColor: "white" }}
              onClick={() => {}}
            >
              {t("send")}
            </button>
          </form>
        </div>
      </SweetAlert>
      {/* Mirrored from duruthemes.com/demo/html/travol/index2.html by HTTrack Website Copier/3.x [XR&CO'2014], Tue, 13 Sep 2023 15:40:03 GMT */}

      {/* Global site tag (gtag.js) - Google Analytics */}
      {/* Preloader */}

      {/* Progress scroll totop */}
      <div className="progress-wrap cursor-pointer">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          <i class="fa-solid fa-up"></i>
        </svg>
      </div>
      {/* Navbar */}
      <Nav />
      {/* Slider */}
      <header className="header slider-fade">
      {/* owl-carousel owl-theme */}
        <div className="">
          {/* The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. */}
          <div
            className="text-center item bg-img"
            style={{
              backgroundColor: "#111",
              backgroundImage: `url(/img/temur.jpg)`,
            }}
          >
             <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.3)", // Semi-transparent black overlay
      zIndex: 1, // Ensures the overlay appears above the background
    }}
  ></div>
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    {/* <h4>{t("twithus")}</h4> */}
                    <h1 className="hero_text">
                      {t("mekka")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="text-center item bg-img"
            style={{
              backgroundImage: `url(./img/slider/s11.jpg)`,
            }}
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <h4>{t("lets")}</h4>
                    <h1>
                      {t("explore")} <span>UZTURAGENCY</span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div
            className="text-center item bg-img"
            style={{
              backgroundImage: `url(./img/slider/s12.jpg)`,
            }}
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <h4>{t("twithus")}</h4>
                    <h1>
                      <span>{t("discover")}</span> {t("woguide")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </header>
      {/*popular tours */}
      <div className="tours3 section-padding bg-lightnav" id="tours">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-subtitle">
                <span>{t("choose")}</span>
              </div>
              <div className="section-title">
                {t("popu")} {t("tours")}
              </div>
            </div>
          </div>
          <div className="row">
            {/* tour card */}
            {popular.map((e, i) => (
              <div className="col-md-4" style={{marginTop: '20px'}}>
                <div >
                  <div className="card">
                    <Link to={e._id}>
                    <img src={`${e.img}`}  />
                    </Link>
                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 10px', fontSize: '12px'}}>
                      <div style={{backgroundColor: "#0c8bcb", color: "#fff", borderRadius: '10px', paddingInline: '2px', marginTop: '2px'}}>
                        {t("business_tour")}
                      </div>
                      <div style={{display: 'flex', alignItems: 'center', fontSize: '14px'}}>
                      <IoLocationOutline />
                        {e.location}
                      </div>
                    </div>
                    <div className=""> 
                    <div onClick={()=>navigation(`/${e._id}`)} style={{padding: '0 10px', cursor: 'pointer', fontSize: '18px'}}>
                    {lang == "en" && e.title_eng}
                    {lang == "ru" && e.title_ru}
                    {lang == "uz" && e.title_uz}
                    </div>
                    <div dangerouslySetInnerHTML={{__html:lang == "en" ? e.description_eng : e.description_ru }} style={{padding: '0 10px', cursor: 'pointer', fontSize: '12px', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2}}>
                    </div>
                    <div>
                    <button
                  className="money glow-on-hover"
                  style={{ color: "black", backgrounColor: "white", width: '100%', marginTop: '10px' }}
                  onClick={() => {
                    setBookShow(true);
                    setData(e)
                  }}
                >
                  {t("book")}
                </button>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* simple tours */}

          {/* endsimple_tours */}
        </div>
      </div>
      {/* Numbers */}
      {/* <section className="numbers">
        <div
          className="section-padding bg-img bg-fixed back-position-center absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(https://cdn.tripster.ru/thumbs2/bd866670-b5c1-11ee-b8f5-3221ccd796ca.1600x900.jpeg)`,
          }}
          data-overlay-dark={100}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="item text-center">
                  {" "}
                  <img src="img/arrow1.png" className="tobotm" alt="" />{" "}
                  <span className="icon">
                    <i class="fa-solid fa-plane-circle-check"></i>
                    <i class="fa-solid fa-jet-fighter"></i>
                  </span>
                  <h3 className="count">600</h3>
                  <h6>Flight Booking</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="item text-center">
                  {" "}
                  <img src="img/arrow1.png" alt="" />{" "}
                  <span className="icon">
                    <i class="fa-solid fa-house"></i>
                    <i class="fa-solid fa-house"></i>
                  </span>
                  <h3 className="count">250</h3>
                  <h6>Amazing Tour</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="item text-center">
                  {" "}
                  <img src="img/arrow1.png" className="tobotm" alt="" />{" "}
                  <span className="icon">
                    <i class="fa-solid fa-ship"></i>
                    <i class="fa-sharp fa-solid fa-ship"></i>
                  </span>
                  <h3 className="count">100</h3>
                  <h6>Cruises Booking</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="item text-center">
                  {" "}
                  <span className="icon">
                    <i class="fa-solid fa-ticket"></i>
                    <i class="fa-solid fa-ticket"></i>
                  </span>
                  <h3 className="count">100</h3>
                  <h6>Ticket Booking</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Italy &  France Tours 2 */}
      {/* Banner Tour Video */}
      {/* <section className="dynamic-video-wrapper" data-overlay-dark={3}>
        <video
          width="100%"
          height="100%"
          autoPlay="autoplay"
          muted
          preload="auto"
          loop="loop"
        >
          <source src="img/travel-video.mp4" type="video/mp4" />
        </video>
        <div className="wrap-content v-middle">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h1>Costa Victoria Cochin</h1>
                <h4>
                  <i className="ti-location-pin" /> Maldives &nbsp;&nbsp;{" "}
                  <i className="ti-timer" /> 4 Days + 3 Nights
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Destination 1 */}
      <section
        className="destination1 section-padding bg-lightnav"
        id="destinations"
      >
        <div className="container" id="gallery">
          <div className="row">
            <div className="col-md-12">
              <div className="section-subtitle">{t("tdes")}</div>
              <div className="section-title">
                {/* {t("gallery")} */}
                 {/* <span> */}
                  {t("odes")}
                  {/* </span> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className=" d-flex flex-wrap justify-content-center">
                {galery?.map((e, i) => (
                  <div
                    className="item semi-card"
                    style={{
                      // height: "400px",
                      borderRadius: "8px",
                      marginRight: "1rem",
                      backgroundImage: `url(${e})`,
                    }}
                  >
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonials */}
      {/* Clients */}
      {/* <section className="clients">
        <div className="container">
          <div className="row">
            <div className="col-md-7 owl-carousel owl-theme">
              <div className="clients-logo">
                <a href="#0">
                  <img src="img/clients/1.png" alt="" />
                </a>
              </div>
              <div className="clients-logo">
                <a href="#0">
                  <img src="img/clients/2.png" alt="" />
                </a>
              </div>
              <div className="clients-logo">
                <a href="#0">
                  <img src="img/clients/3.png" alt="" />
                </a>
              </div>
              <div className="clients-logo">
                <a href="#0">
                  <img src="img/clients/4.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Footer */}
      <Footer />
      {/* jQuery */}
      {/* Mirrored from duruthemes.com/demo/html/travol/index2.html by HTTrack Website Copier/3.x [XR&CO'2014], Tue, 13 Sep 2023 15:40:07 GMT */}
      </div>
  );
};

export default Home;
