import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useContext, useRef, useState, useEffect } from "react";
import Logo from "../../Asset/logo.png";
import { LangContext } from "../../Contexts/Context";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Eng from "../../Asset/eng.png";
import Rus from "../../Asset/rus.png";

// import Logo from './logo.png'
const Nav = () => {
  let newRef = useRef();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { lang, setLang } = useContext(LangContext);
  const { locale, setLocale } = useContext(LangContext);
  const localValue = localStorage.getItem("lang");

  function changeLanguage(e) {
    setLang(e.target.value);
    localStorage.setItem("lang", e.target.value);
    i18n.changeLanguage(localStorage.getItem("lang"));
  }
  console.log(newRef.value);

  useEffect(() => {
    if (localStorage.getItem("lang") == "ru") {
      newRef.current.value = "Russian";
      setLocale("ru");
    } else if (localStorage.getItem("lang") == "en") {
      newRef.current.value = "English";
      setLocale("en");
    }else{
      newRef.current.value = "Russian";
      setLocale("ru"); 
    }
  }, [localValue]);
  return (
    <div>
      <nav className="n-navbar">
        <div className="ncont">
          <div className="nlogo d-flex justify-content-between">
            <a href="/" style={{fontWeight: 'bolder'}} onClick={() => navigate("/")}>
              UZTURAGENCY
            </a>
          </div>
          <ul className="nav-links">
            <input type="checkbox" id="checkbox_toggle" />
            <label htmlFor="checkbox_toggle" className="hamburger">
              ☰
            </label>
            <div className="menu">
              <li className="extraselect">
                <select
                  style={{
                    height: "30px",
                    fontSize: "17px",
                    padding: "1px",
                    borderColor: "white",
                    color: "white",
                  }}
                  ref={newRef}
                  onChange={changeLanguage}
                  class="form-select"
                  id="lang-select"
                  value={locale}
                >
                  <option value="ru">
                    {t("russian")}
                    {"  "}
                    {lang === "ru" && "✔"}
                  </option>
                  <option value="en">
                    <img src={Eng} width={90} height={50} alt="" />
                    {t("english")}
                    {"  "}
                    {lang === "en" && "✔"}
                  </option>
                </select>
              </li>
              <li>
                <a href="/" onClick={() => window.location.reload()}>
                  {t("home")}
                </a>
              </li>
              {/* <li>
                <a href="#about">{t("about")}</a>
              </li> */}
              <li>
                <a href="#tours">{t("tours")}</a>
              </li>
              <li>
                <Link to="#gallery">{t("gallery")}</Link>
              </li>
              {/* <li onClick={() => window.scrollTo({ top: 0 })}>
                <Link to="/contact">{t("contact")}</Link>
              </li> */}
              <li className="ex2">
                <select
                  style={{
                    height: "30px",
                    fontSize: "17px",
                    padding: "1px",
                    borderColor: "white",
                    color: "white",
                  }}
                  ref={newRef}
                  onChange={changeLanguage}
                  class="form-select"
                  id="lang-select"
                  value={locale}
                >
                  <option value="en">
                    <img src="../../Asset/eng.png" alt="" />
                    {t("english")}
                    {"  "}
                    {lang === "en" && "✔"}
                  </option>
                  <option value="ru">
                    <img src="../../Asset/rus.png" alt="" />
                    {t("russian")}
                    {"  "}
                    {lang === "ru" && "✔"}
                  </option>
                </select>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
